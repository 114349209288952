module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fiboo","short_name":"Fiboo","start_url":"/tr","background_color":"#0064C8","theme_color":"#0064C8","display":"standalone","icon":"src/images/fiboo_icon.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-5ZB4NXV3B5"],"gtagConfig":{"optimize_id":"GTM-5C3TK5J","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/iletisim","/hizmetlerimiz"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
