// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guvenlik-politikamiz-tr-js": () => import("../src/pages/guvenlik-politikamiz.tr.js" /* webpackChunkName: "component---src-pages-guvenlik-politikamiz-tr-js" */),
  "component---src-pages-hakkimizda-tr-js": () => import("../src/pages/hakkimizda.tr.js" /* webpackChunkName: "component---src-pages-hakkimizda-tr-js" */),
  "component---src-pages-hizmetler-tr-js": () => import("../src/pages/hizmetler.tr.js" /* webpackChunkName: "component---src-pages-hizmetler-tr-js" */),
  "component---src-pages-iletisim-tr-js": () => import("../src/pages/iletisim.tr.js" /* webpackChunkName: "component---src-pages-iletisim-tr-js" */),
  "component---src-pages-index-tr-js": () => import("../src/pages/index.tr.js" /* webpackChunkName: "component---src-pages-index-tr-js" */),
  "component---src-pages-referanslar-tr-js": () => import("../src/pages/referanslar.tr.js" /* webpackChunkName: "component---src-pages-referanslar-tr-js" */),
  "component---src-pages-terms-and-conditions-en-js": () => import("../src/pages/terms-and-conditions.en.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-en-js" */)
}

